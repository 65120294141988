import React from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { Link } from 'gatsby'
import { Location } from '@reach/router'
import LogoIcon from '../../svg/LogoIcon'
import Button from '../Button'

const Header = () => (
  <Location>
    {({ location }) => (
      <header className="sticky top-0 bg-white shadow z-50 bg-hero-texture bg-gray-200">
        <div className="container flex flex-col sm:flex-row justify-between items-center mx-auto px-8 py-2 md:py-4 ">
          <div className="flex items-center text-2xl">
            <Link to="/">
              <div className="flex flex-grow items-center text-gray-700 text-center">
                <div className="w-8 h-6">
                  <LogoIcon />
                </div>
                <div className="uppercase font-semibold leading-tight">FinStar</div>
              </div>
            </Link>
          </div>

          {location.pathname === '/' && (
            <>
              <div className="flex mt-4 sm:mt-0">
                <AnchorLink className="px-4 hover:bg-blue-100" href="#features">
                  Features
                </AnchorLink>
                <AnchorLink className="px-4 hover:bg-blue-100" href="#pricing">
                  Pricing
                </AnchorLink>
                <AnchorLink className="px-4 hover:bg-blue-100" href="#testimonials">
                  Testimonials
                </AnchorLink>
              </div>
              <div className="hidden md:block">
                <Button className="text-sm" to="/demo">
                  Schedule a Demo
                </Button>
              </div>
            </>
          )}
        </div>
      </header>
    )}
  </Location>
)

export default Header
