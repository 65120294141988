import React from 'react'

const Footer = () => (
  <footer className="w-full b-0">
    <div style={{ height: '150px', overflow: 'hidden' }}>
      <svg
        viewBox="0 0 500 150"
        preserveAspectRatio="none"
        style={{ height: '100%', width: '100%' }}
      >
        <path
          d="M-1.41,34.03 C149.99,150.00 349.20,-49.98 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
          style={{ stroke: 'none', fill: '#63B3ED' }}
        />
      </svg>
    </div>
    <div className="text-white bg-blue-400 w-full mx-auto text-center">
      &copy; {new Date().getFullYear()} <a href="https://visionap.com">VisionAp</a>
    </div>
  </footer>
)

export default Footer
