import React from 'react'

export default () => (
  <section className="relative z-0 bg-primary-darker">
    <div className="absolute w-full">
      <div className="bg-blue-400 h-20" />
      <div className="w-full">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path
            fill="#63B3ED"
            fillOpacity={1}
            d="M0,288L40,272C80,256,160,224,240,197.3C320,171,400,149,480,149.3C560,149,640,171,720,197.3C800,224,880,256,960,256C1040,256,1120,224,1200,202.7C1280,181,1360,171,1400,165.3L1440,160L1440,0L1400,0C1360,0,1280,0,1200,0C1120,0,1040,0,960,0C880,0,800,0,720,0C640,0,560,0,480,0C400,0,320,0,240,0C160,0,80,0,40,0L0,0Z"
          />
        </svg>
      </div>
    </div>
  </section>
)
