import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { graphql, useStaticQuery } from 'gatsby'
import icon from '../images/VisionapLogo.png'

const Seo = ({ pathname }) => {
  const {
    site: {
      siteMetadata: { siteUrl, title, description },
    },
  } = useStaticQuery(graphql`
    query Seo {
      site {
        siteMetadata {
          siteUrl
          title
          description
        }
      }
    }
  `)

  return (
    <Helmet defer={false} defaultTitle={title} titleTemplate={`%s | ${title}`}>
      <html lang="en" />
      <link rel="canonical" href={`${siteUrl}${pathname}`} />
      <meta name="docsearch:version" content="2.0" />
      <meta
        name="viewport"
        content="width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover"
      />
      <meta name="description" content={description} />
      <meta name="theme-color" content="#1e78c2" />
      <meta property="og:url" content={siteUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content="en" />
      <meta property="og:site_name" content={title} />
      <meta property="og:image" content={`${icon}`} />
      <meta property="og:image:width" content="512" />
      <meta property="og:image:height" content="512" />
    </Helmet>
  )
}

Seo.defaultProps = {
  pathname: '',
}

Seo.propTypes = {
  pathname: PropTypes.string,
}

export default Seo
