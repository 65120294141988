import React from 'react'
import PropTypes from 'prop-types'
import Footer from './Footer'
import Header from './Header'
import Seo from '../Seo'
import Wave from '../../svg/Wave'

const Layout = ({ children }) => {
  return (
    <div className="bg-hero-topography min-h-screen flex flex-col">
      <Seo />
      <Header />
      <Wave />
      <main className="text-gray-900 relative pt-12 flex-grow">{children}</main>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}

export default Layout
