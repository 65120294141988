/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'

const sizes = {
  default: `py-3 px-8`,
  lg: `py-4 px-12`,
  xl: `py-5 px-16 text-lg`,
}

const Button = ({ children, className = '', size, to, ...props }) => {
  const disabledClasses = props.disabled ? 'opacity-50 cursor-not-allowed' : ''
  if (to) {
    return (
      <Link
        to={to}
        className={`${sizes[size] || sizes.default}
        ${className}
        bg-primary-darker
        hover:bg-primary
        hover:text-white
        rounded
        text-white
        ${disabledClasses}`}
        {...props}
      >
        {children}
      </Link>
    )
  }
  return (
    <button
      type="button"
      className={`
        ${sizes[size] || sizes.default}
        ${className}
        bg-primary-darker
        hover:bg-primary
        rounded
        text-white
        ${disabledClasses}
    `}
      {...props}
    >
      {children}
    </button>
  )
}

Button.defaultProps = {
  className: '',
  to: undefined,
  size: undefined,
}

Button.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string,
  to: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}
export default Button
